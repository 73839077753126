import classNames from 'classnames';
import { SerialisedIndexData } from 'elasticlunr';
import { graphql } from 'gatsby';
import * as React from 'react';

import { ISearchResult } from '@hub-cmd/gatsby';

import ExternalLink from '@hub-fe/common/ExternalLink';
import { IconRightArrow } from '@hub-fe/common/Icons';
import { siteName } from '@hub-fe/common/siteInfo.variants';
import GatsbySiteLink from '@hub-fe/landing/GatsbySiteLink';
import { DamlHubHelmet, DocsNav } from '@hub-fe/landing/nav';

const EXPOSE_SITE_OPENAPI_DOCS = __EXPOSE_SITE_OPENAPI_DOCS__.toLowerCase() === 'true';

// Gatsby query
// ts-unused-exports:disable-next-line
export const query = graphql`
    query {
        siteSearchIndex {
            index
        }
        allMarkdownRemark(
            sort: { order: ASC, fields: frontmatter___position }
            filter: { fields: { depth: { eq: 1 } } }
        ) {
            edges {
                node {
                    id
                    html
                    fields {
                        depth
                        slug
                    }
                    frontmatter {
                        position
                        description
                        title
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`;

interface IQueryResult {
    siteSearchIndex: {
        index: SerialisedIndexData<ISearchResult>;
    };
    allMarkdownRemark: {
        edges: { node: IQueryResultNode }[];
    };
}

interface IQueryResultNode {
    id: string;
    html: string;
    fields: {
        depth: number;
        slug: string;
    };
    frontmatter: {
        position: number;
        description: string;
        title: string;
        role: string;
    };
}

interface IIndexPageProps {
    data: IQueryResult;
}

const IndexPage: React.FC<IIndexPageProps> = props => {
    const searchIndex = props.data.siteSearchIndex.index;

    return (
        <div className="docs-index">
            <DocsNav hideBreadcrumbs searchIndex={searchIndex} />
            <MainContent />
        </div>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default IndexPage;

const MainContent = () => {
    const iconColorClass = 'icon-black';
    const siteOpenApiDocs = (
        <GatsbySiteLink
            className="box"
            link={{
                label: '',
                href: `/docs/all-site-api`,
                internal: true,
            }}
        >
            <div className="box-content preview">
                <div className="box-text">
                    <h2>Site-Level API Documentation (All)</h2>
                    <p>All APIs exposed on api.hub.daml.com, hub.daml.com and login.hub.daml.com</p>
                </div>
                <IconRightArrow />
            </div>
        </GatsbySiteLink>
    );

    const userManagementGuides = (
        <GatsbySiteLink
            className="box"
            link={{
                label: '',
                href: `/docs/guides/api`,
                internal: true,
            }}
        >
            <div className="box-content">
                <div className="box-text">
                    <h2>API and Automation Guide</h2>
                    <p>
                        Guides for using authentication, automations, party management, and user
                        management on Hub
                    </p>
                </div>
                <IconRightArrow />
            </div>
        </GatsbySiteLink>
    );

    return (
        <div className="main-content">
            {/* TODO - use the query to get this information */}
            <DamlHubHelmet
                title={`${siteName} Docs`}
                meta={[{ name: 'site', content: 'damlhub' }]}
            />
            <div className={classNames('docs-boxes', iconColorClass)}>
                <GatsbySiteLink
                    className="box"
                    link={{
                        label: '',
                        href: '/docs/quickstart/console',
                        internal: true,
                    }}
                >
                    <div className="box-content">
                        <div className="box-text">
                            <h2>Console Overview</h2>
                            <p>How to use the {siteName} console</p>
                        </div>
                        <IconRightArrow />
                    </div>
                </GatsbySiteLink>

                <GatsbySiteLink
                    className="box"
                    link={{
                        label: '',
                        href: '/docs/quickstart/overview',
                        internal: true,
                    }}
                >
                    <div className="box-content">
                        <div className="box-text">
                            <h2>Daml Hub Overview</h2>
                            <p>How to use {siteName}</p>
                        </div>
                        <IconRightArrow />
                    </div>
                </GatsbySiteLink>

                <GatsbySiteLink
                    className="box"
                    link={{
                        label: '',
                        href: `/docs/guides/console`,
                        internal: true,
                    }}
                >
                    <div className="box-content">
                        <div className="box-text">
                            <h2>Console Guides</h2>
                            <p>
                                Guides for console functions such as uploading automations, using
                                triggers, and automating and managing participant/synchronizer
                                connections
                            </p>
                        </div>
                        <IconRightArrow />
                    </div>
                </GatsbySiteLink>

                {userManagementGuides}

                <GatsbySiteLink
                    className="box"
                    link={{
                        label: '',
                        href: `/docs/app-api`,
                        internal: true,
                    }}
                >
                    <div className="box-content">
                        <div className="box-text">
                            <h2>Application API Documentation</h2>
                            <p>
                                How to use the REST and Automation APIs exposed on your
                                daml.app-hosted subdomain
                            </p>
                        </div>
                        <IconRightArrow />
                    </div>
                </GatsbySiteLink>

                <GatsbySiteLink
                    className="box"
                    link={{
                        label: '',
                        href: '/docs/site-api',
                        internal: true,
                    }}
                >
                    <div className="box-content">
                        <div className="box-text">
                            <h2>Site-Level API Documentation</h2>
                            <p>How to use the APIs exposed on hub.daml.com</p>
                        </div>
                        <IconRightArrow />
                    </div>
                </GatsbySiteLink>

                {EXPOSE_SITE_OPENAPI_DOCS && siteOpenApiDocs}
            </div>
            <div className="resources-boxes">
                <h2>Resources</h2>
                <div className="resource-box">
                    <h4>Daml SDK</h4>
                    <p>
                        Developer tools for building applications with the open-source contract
                        language.
                    </p>
                    <p>
                        Instructions on how to install the Daml SDK can be found in the{' '}
                        <ExternalLink
                            icon
                            to="https://docs.daml.com/getting-started/installation.html"
                        >
                            Daml Docs
                        </ExternalLink>
                    </p>
                </div>
                <div className="resource-box">
                    <h4>DAZL Python Library</h4>
                    <p>
                        A python library to write automation bots for {siteName}. Available on{' '}
                        <ExternalLink icon to="https://github.com/digital-asset/dazl-client">
                            Github
                        </ExternalLink>
                    </p>
                </div>
            </div>
        </div>
    );
};
